import React from 'react';
import { Link } from 'gatsby';

import StarIcon from '../../assets/images/star-icon.png';
import OurMissionShape from '../../assets/images/our-mission/our-mission-shape2.png';
import OurMissionImg from '../../assets/images/our-mission/our-mission1.png';
import OurMissionShape1 from '../../assets/images/our-mission/our-mission-shape1.png';

const OurMission = () => {
    return (
        <section className="our-mission-area ptb-100">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-content">
                            <div className="content">
                                <span
                                    className="sub-title"
                                    style={{ display: 'block', paddingTop: '10px' }}
                                >
                                    <img src={StarIcon} alt="image" /> 
                                    AI-Driven Innovation & Business Transformation
                                </span>
                                <h2>Optimizing Enterprise Solutions with AI & Cloud</h2>
                                <p>
                                    We specialize in AI/ML consulting, software development, and intelligent automation to help businesses scale and optimize operations. Our expertise spans cloud-based CRM, ERP systems, and AI-driven workflow automation, delivering measurable efficiency and innovation.
                                </p>
                                <p>
                                    As pioneers in orchestrated multi-agent systems (OMNAgent), we are redefining how businesses leverage AI for intelligent decision-making. By integrating cloud-native architectures with AI, we create scalable, data-driven solutions that enhance business intelligence, customer experience, and operational performance.
                                </p>
                                
                                <ul className="our-mission-list">
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        AI & Machine Learning Solutions
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Cloud-Based CRM & ERP Expertise
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Orchestrated Multi-Agent Systems (OMN)
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                    <li>
                                        <i className="flaticon-tick"></i>
                                        Scalable Software Development & Integration
                                        <img src={OurMissionShape} alt="Our Mission Shape" />
                                    </li>
                                </ul>
                                <p>
                                    {/* <Link to="/contact" className="btn btn-primary">
                                        Get in Touch
                                    </Link> */}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="our-mission-image">
                            <img src={OurMissionImg} alt="image" />
                            <div className="shape">
                                <img src={OurMissionShape1} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OurMission;
